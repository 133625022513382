import styles from "./index.module.scss";
import { FC, useEffect } from "react";
import classNames from "classnames";
import { Icon } from "@/shared/components/Icon";
interface IStars {
  stars: number;
  className?: string;
  isAllStars?: boolean;
}
const isFractionGreaterThanHalf = (number: number) => {
  const fractionalPart = number % 0.999;
  return fractionalPart > 0.2;
};
// import { Stars } from "@/components/stars/stars";
const Stars: FC<IStars> = props => {
  const {
    stars,
    className,
    isAllStars
  } = props;
  const wrapClassnames = classNames({
    [className!]: className,
    [styles.wrap]: true
  });
  const renderStars = (stars: number, pos: number) => {
    if (stars > pos) {
      if (isFractionGreaterThanHalf(stars) && stars.toFixed() === pos.toString()) {
        return <Icon name={"StarIconHalf"} />;
      } else {
        return <Icon name={"StarActive"} />;
      }
    } else {
      return <Icon name={"Star"} />;
    }
  };
  return isAllStars ? <div className={wrapClassnames} data-sentry-component="Stars" data-sentry-source-file="index.tsx">
      {renderStars(Number(stars), 0)}
      {renderStars(Number(stars), 1)}
      {renderStars(Number(stars), 2)}
      {renderStars(Number(stars), 3)}
      {renderStars(Number(stars), 4)}
    </div> : <div className={wrapClassnames} data-sentry-component="Stars" data-sentry-source-file="index.tsx">{renderStars(Number(stars), 0)}</div>;
};
export default Stars;