"use client";

import styles from "./index.module.scss";
import { FC } from "react";
import { ICardMini } from "@/shared/types/types";
import { usePathname } from "next/navigation";
import AddToWishlist from "@/card/AddToWishlist";
interface IActions {
  card: ICardMini;
  onClickFavourite?: () => void;
}
const Actions: FC<IActions> = props => {
  const {
    card,
    onClickFavourite
  } = props;
  const pathname = usePathname();
  return !card.comission && <>
        <div className={styles.actions}>
          {card && card.name && card.price_discount ? <AddToWishlist price={card.price_discount} name={card.name} id={Number(card.id)} callBack={onClickFavourite} /> : null}
        </div>
      </>;
};
export default Actions;